<template>
  <div class="head">
    <div class="main">
      <div class="position f14 cursor m-left-20">
        <i class="el-icon-location" />
        <span>上海</span>
        <span class="m-left-20">{{ CompanyName }}</span>
      </div>
      <!-- hidden-sm-and-down -->
      <div class="right hidden-sm-and-down">
        <span class="p-lr-20 f14 cursor" @click="PunchBuy">进入买家版</span>
        <span>
          <span v-if="isLogin" class="p-lr-20 f14 ">{{ '你好，' + userName + '  ' }}</span>
          <span v-if="isLogin" class="p-lr-20 f14 cursor" @click="quit(isLogin)">退出登录</span>
          <span v-if="!isLogin" class="p-lr-20 f14 cursor">你好，请登录</span>
        </span>
        <!-- <el-divider direction="vertical" />
        <span class="f14 p-lr-20 cursor" style="color:#FFDC73">免费注册</span>
        <el-divider direction="vertical" />
        <span class="p-lr-20 f14 cursor">Mai47  首页</span>
        <el-divider direction="vertical" /> -->

        <!-- <span v-if="CompanyType === 4" class="p-lr-20 f14 cursor">我是卖家</span> -->
        <!-- <el-divider v-if="CompanyType === 4" direction="vertical" /> -->
        <!-- <span class="p-lr-20 f14 cursor">客服服务</span> -->
      </div>
      <div class="el-icon-more_">
        <!-- @command="handleCommand" -->
        <el-dropdown>
          <span class="el-dropdown-link">
            <i class="el-icon-more" />
          </span>
          <el-dropdown-menu slot="dropdown">
            <div class="flex column">
              <span class="p-lr-20 f14 cursor" @click="PunchBuy">进入买家版</span>
              <span v-if="isLogin" class="p-lr-20 f14 m-tb-5">{{ '你好，' + userName + '  ' }}</span>
              <span v-if="isLogin" class="p-lr-20 f14  cursor" @click="quit(isLogin)">退出登录</span>
              <span v-if="!isLogin" class="p-lr-20 f14 m-tb-5 cursor">你好，请登录</span>
            </div>
          </el-dropdown-menu>
        </el-dropdown>

      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: 'Head',
  computed: {
    isLogin() {
      return this.getCookie('token')
    },
    userName() {
      return JSON.parse(this.getCookie('UserInfoDetails')).UserName
    },
    CompanyType() {
      return JSON.parse(this.getCookie('UserInfoDetails')).CompanyType
    },
    CompanyName() {
      return JSON.parse(this.getCookie('UserInfoDetails')).CompanyName
    }
  },
  methods: {
    getCookie(name) {
      var arr; var reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
      // eslint-disable-next-line no-cond-assign
      if (arr = document.cookie.match(reg)) { return unescape(arr[2]) } else { return null }
    },
    setCookie(cname, cvalue, exdays) {
      var d = new Date()
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
      var expires = 'expires=' + d.toUTCString()
      document.cookie = cname + '=' + cvalue + '; ' + expires
    },
    PunchBuy() {
      this.$api.PunchBuy().then(res => {
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        window.open(res.Redirect)
      })
    },
    quit(isLogin) {
      if (!isLogin) return
      // if (!this.getCookie('_ticket')) return this.$message.error('ticket不存在')
      // {
      //   _ticket: this.getCookie('_ticket'),
      //   _token: this.getCookie('token')
      // }
      console.log(document.cookie)
      this.$api.logOut({
        _ticket: this.getCookie('RundaB2BTicket')
      }).then(res => {
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        localStorage.removeItem('token')
        // document.cookie = '_ticket' + '=|' + '' + '; '
        // document.cookie = 'token' + '=|' + '' + '; '
        this.setCookie('_ticket', '', -1)
        this.setCookie('RundaB2BTicket', '', -1)
        this.setCookie('token', '', -1)
        this.setCookie('UserInfo', '', -1)
        this.setCookie('UserInfo.Guid', '', -1)
        sessionStorage.clear()
        this.$message.success(res.RetMsg)
        // this.$router.push('/login')
        window.location.href = this.$Url
      }).catch(err => {
        return this.$message.error('错误：' + err)
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.head{
   width:100%;
    height: 30px;
    line-height:100%;
    background: #3A78F1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;
    // background-color:#e3e7ee;
    .main{
      // min-width: 768px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      //  padding: 0 4%;

    }
    .position{
      //  padding: 0 4%;
     min-width: 235px;
      height: 100%;
      text-align: center;
      color:#fff;
    }
    .right{
      flex: 1;
      min-width: 768px;
      display: flex;
      justify-content: flex-end;
      color:#fff;
    }
}

.head::-webkit-scrollbar {
  display: none;
}
.el-icon-more_{
  display: none;
}
@media screen and (max-width: 991px) {
    .el-icon-more_{
        flex: 1;
        display: block;
        margin-right: 20px;
        text-align: right;
    }
}
</style>

<template>
  <div class="main-box">
    <div class="main-box-tab" :style="{paddingLeft: isShowBtn ? '20px':'0px' }">
      <i v-if="isShowBtn" class="el-icon-arrow-left p-tb-10 p-lr-10" style="background-color: #fff;" @click="previous" />
      <i v-if="isShowBtn" class="el-icon-arrow-right  p-tb-10 p-lr-10" style="background-color: #fff" @click="next(false)" />
      <div ref="tabs" class="main-box-tab-content">
        <div ref="boxScorll" class="main-box-tab-roll" :style="{marginLeft:isShowBtn ?'20px': '0px' }">
          <el-tag
            v-for="(tag,index) in $store.state.index.tags"
            :key="index"
            class="cursor tag select_none m-right-10"
            :type="$route.fullPath === tag.fullPath ? 'info' : ''"
            :color="$route.fullPath === tag.fullPath ? '#ffffff' :'#F5F6FA' "
            @click.stop="clickTab(tag,index)"
          >
            <div class="flex a-center" :style="{color: $route.fullPath === tag.fullPath ? '#409EFF' :'#333',height:'100%'}">
              {{ tag.meta.title }}
              <span class="close_a">
                <i class="el-icon-close  cursor" @click.stop="close(tag)" />
              </span>
            </div>

          </el-tag>
        </div>
      </div>
    </div>
    <div class="right f14 cursor">
      <router-link target="_blank" :to="{name: 'helpCenter'}">
        <i class="el-icon-question p-right-5" />
        帮助中心
      </router-link>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Tages',
  data() {
    return {
      viewItemInfo: {},
      isShowBtn: false,
      numSwiper: 0,
      widthWindow: 0,
      widthSwiper: 0,
      isWidth: 0,
      maxNum: 0,
      tabs: this.$store.state.index.tags,
      moveX: 0,
      count: 1,
      unoccupied: 0,
      tabsCount: 0,
      actionName: '',
      tag_width_all: 0, // 所有tag的宽度综合
      last_moveX: 0, // 记录最终一个滑动点
      flag: false,
      tempObj: [] // 记录偏移量
    }
  },

  watch: {
    isShowBtn(a) {
      // console.log(a)
    },
    actionName(val) {
      console.log('val', val)
      // const len = this.$store.state.index.tags.length
      // // 如有重复数据退出后续函数执行
      // for (let i = 0; i < len; i++) {
      //   // console.log(this.$store.state.index.tags[i].fullPath, val)
      //   if (this.$store.state.index.tags[i].fullPath === val) {
      //     this.$nextTick(() => {
      //       this.translateX((i + 1 - this.tabsCount) * this.width - this.unoccupied)
      //     })
      //     return
      //   }
      // }
      // this.$nextTick(() => {
      //   // (总共有多少个tabs - 未偏移时可见的元素个数) * 单个tab标签元素长度 - 被遮挡tab元素的可见部分的宽度
      //   this.translateX((this.$store.state.index.tags.length - this.tabsCount) * this.width - this.unoccupied)
      // })
    },
    '$store.state.index.tags': {
      handler(a) {
        this.$nextTick(() => {
          this.actionName = a[a.length - 1].fullPath
          this.getTagsWidth()
          setTimeout(() => {
            this.next(true)
          }, 60)
        })
      }, deep: true
    },
    '$store.state.index.isFullPath': {
      handler(a) {
        this.$nextTick(() => {
          const len = this.$store.state.index.tags.length
          // 如有重复数据退出后续函数执行
          for (let i = 0; i < len; i++) {
            if (this.$store.state.index.tags[i].fullPath === a) {
              this.$nextTick(() => {
                this.translateX((i + 1 - this.tabsCount) * this.width - this.unoccupied)
              })
              return
            }
          }
        })
      }, deep: true
    }
  },
  mounted() {
    const this_ = this
    this.$nextTick(() => {
      const tabs = this.$refs.tabs
      // const getStyle = getComputedStyle(tabs.children[0].children[0], null)
      // const marginLeft = parseFloat(getStyle.marginLeft.substr(0, getStyle.marginLeft.length - 1))
      // const marginRight = parseFloat(getStyle.marginRight.substr(0, getStyle.marginRight.length - 1))
      // 元素实际宽度 = 元素的宽度 + 外边距
      this.width = 124 // 142
      // this.width = this.width - 30
      /**
       * 以下注释计算方式用于理解实现逻辑
       **/
      // // 可视区域能放入多少个元素 = 可视区域的宽度 / 子元素实际宽度
      // let num = tabs.offsetWidth / this.width

      // // 被遮挡tab元素的可见部分的宽度 = 可见区域的宽度 - (子元素实际宽度 * num转为整数)
      // this.unoccupied = tabs.offsetWidth - (this.width * parseInt(num))

      // 最终精简为取余（得数跟上面的计算是一样的）
      this.unoccupied = tabs.offsetWidth % this.width
      // 转为整数
      this.tabsCount = parseInt(tabs.offsetWidth / this.width)
    })
    window.addEventListener('resize', function() {
      this_.$nextTick(() => {
        this_.getTagsWidth()
      })
    })
  },
  methods: {

    /**
     * 切换tab标签页
     **/
    clickTab(item, index) {
      if (this.actionName !== item.fullPath) {
        this.actionName = item.fullPath
      }
      this.viewItemInfo = item
      this.$router.push({ name: item.name })
    },

    /**
     * 关闭tab标签页
     **/
    close(item) {
      // console.log(item)
      // const len = this.$store.state.index.tags.length
      // const jumpName = null
      if (this.$store.state.index.tags.length !== 1) {
        const newArr = this.$store.state.index.cachedViews.filter(item1 => item1 !== item.name)
        this.$store.dispatch('index/setCachedViews', newArr)
        this.$store.state.index.tags.splice(this.$store.state.index.tags.indexOf(item), 1)
        this.$router.push({
          path: this.$store.state.index.tags[this.$store.state.index.tags.length - 1].fullPath
        })
        this.actionName = this.$store.state.index.tags[this.$store.state.index.tags.length - 1].fullPath
        this.$nextTick(() => {
          this.getTagsWidth()
          this.previous()
        })
      } else {
        this.$message({
          message: '当前页为最后一页',
          type: 'warning'
        })
      }
    },

    /**
     * 往右偏移
     **/
    next(length) {
      // scrollWidth获取不准确
      // 使用this.width * this.tabs.length计算出总长度
      this.$nextTick(() => {
        // const totalWidth = this.width * (this.$store.state.index.tags.length)
        this.getTagsWidth()
        const totalWidth = this.tag_width_all
        const dom = this.$refs.tabs
        // 可视区域 < 滚动区域（滚动区域大于可视区域才可以移动）
        // 移动距离 + 可视区域 = 滚动区域的宽度（上一次的宽度，当点击时才是实际宽度）< 滚动区域
        if (dom.clientWidth < totalWidth && this.moveX + dom.clientWidth < totalWidth) {
          // this.moveX为0减去空余空间的宽度
          this.moveX += this.moveX ? this.width : this.width - this.unoccupied
          this.tempObj.push(this.moveX)
          console.log('往右偏移', this.moveX)
          console.log('记录的数据', this.tempObj)
          if (length) {
            this.last_moveX = this.last_moveX += this.last_moveX ? this.width : this.width - this.unoccupied
            return this.translateX(this.last_moveX)
          }
          this.translateX(this.moveX)
        }
      })
    },

    /**
     * 往左偏移
     **/
    previous() {
      if (this.moveX > 0) {
        this.moveX -= this.width
        this.translateX(this.moveX)
        console.log('往左偏移', this.moveX)
      }
    },
    /**
     * 开始移动dom
     **/
    translateX(x) {
      this.moveX = x < 0 ? 0 : x
      // console.log('开始滑动', this.moveX)
      this.$refs.tabs.children[0].style.transform = `translateX(-${this.moveX}px)`
    },
    // 获取一个tag的宽度
    getTagWidth_() {
      this.$nextTick(() => {
        let width_ = 0
        const dom1 = document.getElementsByClassName('tag')
        for (let i = 0; i < dom1.length; i++) {
          width_ += dom1[i].offsetWidth + 20
        }

        return width_
      })
    },
    // 获取tags的宽度
    getTagsWidth() {
      // cjq-tag
      this.$nextTick(() => {
        let width_ = 0
        const dom1 = document.getElementsByClassName('tag')
        // const dom = document.getElementsByClassName('main-box-tab-roll')[0].offsetWidth
        const dom = this.$refs.tabs.offsetWidth
        // this.widthWindow = document.getElementsByClassName('left')[0].offsetWidth
        for (let i = 0; i < dom1.length; i++) {
          width_ += dom1[i].offsetWidth + 10
        }
        this.tag_width_all = width_
        if (width_ >= dom) {
          return this.isShowBtn = true
        }
        return this.isShowBtn = false
      })
    },
    // 先获取当前宽度能容下多少个tag
    getWidthNum() {
      this.$nextTick(() => {
        let width_cjq = 0
        const dom1_cjq = document.getElementsByClassName('tag')
        const width_offset = this.$refs.tabs.offsetWidth
        for (let i = 0; i < dom1_cjq.length; i++) {
          width_cjq += dom1_cjq[i].offsetWidth + 10
          if (width_cjq >= width_offset) {
            return this.maxNum = i
          }
        }
      })
    }
  }
}
</script>
<style scoped>
::-webkit-scrollbar {
  display: none;
}
.el-tag {
  color: #333  !important;
  position: relative !important;
  display: inline-block;
}
</style>
<style lang='scss' scoped>
.cjq-tag {
  width: calc(100% - 80px);
  //width: 100%;
  overflow-x: scroll;
  //white-space: nowrap;
  //display: inline-block;
  position: relative;
  display: inline-block;
}
.close_a {
  width: 16px;
  height: 33px;
  color: #333;
  text-align: center;
}
.close_a :hover{
  background-color: skyblue;
}
.close_a .el-icon-close:hover {
  color: #fff;
  text-align: center;

}
.cjq-q {
  //width: auto;
  //padding-left: 0;
  //margin: 0;
  //float: left;
  //list-style: none;
  //box-sizing: border-box;
  position: relative;
  height: 35px;
  display: inline-block;
  white-space: nowrap;
  transition: transform .5s ease-in-out;
}

.tag {
  //display: flex;
  //align-items: center;
  display: inline-block;
  position: relative;
}

.tags{
    //width: 100%;
    height: 35px;
    margin: 10px 0;
    margin-bottom: 0;
    flex:1;
    box-sizing: border-box;
    //display: flex;
    //justify-content: space-between;
    //align-items: center;
  .left-btn {
    //position: absolute;
    //left: 0;
    //top: 0;
    width: 50px;
    height: 35px;
    background-color: #fff;
    display: inline-block;
    text-align: center;
    line-height: 35px;
    //margin-right: 10px;
  }
    .left{
        width: calc(100% - 50px);
        //height: auto;
        //flex: 1;
        display: flex;
        ////justify-content: flex-start;
        flex-wrap:  nowrap;
        ////align-items: center;
        //-webkit-box-sizing: border-box;
        //white-space: nowrap;
        ////box-sizing: border-box;
        //position: relative;
        //overflow: hidden;
        //background: red;
      //overflow-x: auto;
        position: relative;
        //left: 0;
        //bottom: 0;

        .close_a :hover{
          background-color: skyblue;
        }
        .close_a .el-icon-close:hover {
          color: #fff;
          text-align: center;

        }
       //.el-tag--dark.el-tag--info .el-tag__close{
       //   color: #333  !important;
       //   position: absolute !important;
       //   top: -0 !important;
       //   right: -0 !important;
       //}
    }
    .right{
      width: 50px;
      flex-grow: 0;
      flex-shrink: 0;
      border-radius: 4px;
      background: #EEF1F8;
      height: 34px;
      line-height: 34px;
      text-align: center;
      //padding: 0 10px;
      //display: inline-block;
      box-sizing: border-box;
      //position: fixed;
      //right: 0;
      //top: 0;
         i{ color:#4DA6FF;}
      }
      .right:hover{ background: #fff;}
}
.el-tag{ font-size: 14px; line-height: 34px!important; border-radius: 4px 4px 0 0;}
.el-tag .el-icon-close{ line-height: 18px!important;}
.el-tag--dark.el-tag--info .el-tag__close{
    color: #333  !important;
}
.m-top-15{ margin-top: 15px;}
a {
  text-decoration: none;
  color: #000;
}

.router-link-active {
  text-decoration: none;
}
</style>
<style lang="scss" scoped>
.right{
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 4px;
  background: #EEF1F8;
  height: 34px;
  line-height: 34px;
  text-align: center;
  padding:  0 20px;
  //float: right;
  //padding: 0 10px;
  //display: inline-block;
  box-sizing: border-box;
  //position: fixed;
  //right: 0;
  //top: 0;
  i{ color:#4DA6FF;}
}
.right:hover{ background: #fff;}
.main-box {
  //width: calc(100% -  130px);
  height: 35px;
  //width: 500px;
  padding: 10px 20px 20px 20px;
  padding-left: 0;
  padding-bottom: 0px;
  padding-right: 0;
  display: flex;
  justify-content: space-between;
  .main-box-tab {
    position: relative;
    //padding: 10px 20px;
    overflow: hidden;
    width: calc(100% - 120px);
    //padding-left: 20px;

    & > i {
      position: absolute;
      cursor: pointer;
      //bottom: 15px;
      z-index: 5;

      &:nth-child(1) {
        left: 0;
      }

      &:nth-child(2) {
        right: 0;
      }
    }

    .main-box-tab-content {
      overflow: hidden;

      .main-box-tab-roll {
        transition: transform .5s;
        display: flex;
        align-items: center;
        //margin-left: 20px;

        div {
          flex-shrink: 0;
          cursor: pointer;
          //width: 130px;
          height: 32px;
          line-height: 32px;
          margin: 0 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          span, i {
            font-size: 12px;
          }

          span {
            //margin-left: 10px;
            //overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          i {
            margin-right: 10px;
          }
        }
      }
    }

    .tab-item {
      color: #cccccc;
      background-color: rgb(245, 246, 250);
      //background-color: rgba(255, 255, 255, .5);
      border-radius: 0 1px 0 1px;
      //border: 1px solid #052141;
    }

    .tab-item-action {
      background: #fff;
      color: rgb(64, 158, 255);
      border-radius: 0 1px 0 1px;
      //border: 1px solid #1E2088;
    }

    //.close_tag {
    //    height: 32px;
    //  line-height: 34px;
    //}
    //.close_tag:hover {
    //   background-color: ;
    //}
  }

  .main-box-content {
    height: calc(100% - 70px);
    padding: 10px;
    //border: 1px saddlebrown solid;
    background-size: 100% 100%;
  }
  .left_view {
    width: 50px;
    height: 35px;
    background-color: #fff;
    display: inline-block;
    text-align: center;
    line-height: 35px;
  }
}
</style>

<template>
  <div class="setoldpwd" style="z-index:9999">
    <el-dialog class="DepartmentBox" destroy-on-close :visible.sync="setoldquesson" :show-close="false" width="600px" :append-to-body="true" :close-on-click-modal="false">
      <div class="PageHeader1 min-border-bottom flex j-between">
        <span class="Name">问题反馈</span>
        <i class="el-dialog__close el-icon el-icon-close Close" @click="() => setoldquesson = false" />
      </div>
      <div class="p-tb-20 fankui">
        <el-form ref="form" :model="form" :rules="rules1" label-width="120px" class="demo-ruleForm">
          <el-form-item label="用户：" prop="user">
            <div class="flex ">
              <el-input v-model="form.user" disabled size="mini" />
              <!-- <span class="theme-color f12 p-left-20 cursor" @click="$router.push({name:'FindPassword'})">忘记密码？</span> -->
            </div>

          <!-- <el-input v-model="ruleForm.City" size="mini" /> -->
          </el-form-item>
          <el-form-item label="手机号：" prop="Telphone">
            <el-input v-model="form.Telphone" size="mini" />
          </el-form-item>
          <el-form-item label="提交问题：" prop="Desc">
            <el-input v-model="form.Desc" type="textarea" :rows="2" size="mini" />

          <!-- <el-input v-model="ruleForm.Area" size="mini" /> -->
          </el-form-item>
          <el-form-item label="解决意见：" prop="Suggestion">
            <el-input v-model="form.Suggestion" type="textarea" :rows="2" size="mini" />
          <!-- <el-input v-model="ruleForm.Area" size="mini" /> -->
          </el-form-item>
        </el-form>
        <div class="caozuo t-right p-top-20 t_line_s m-top-20">
          <el-button @click="() => setoldquesson = false">取消</el-button>
          <el-button type="primary" :loading="isWait" @click="submit('form')">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'SetoldQuession',
  data() {
    return {
      setoldquesson: false,
      form: {
        Telphone: '',
        user: '',
        Desc: '',
        Suggestion: ''
      },
      isWait: false,
      rules1: {
        Telphone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: (rule, value, callback) => {
            // 验证手机号
            if (!value) {
              callback(new Error('请输入手机号'))
            } else if (!this.$minCommon.checkMobile(value)) {
              callback(new Error('请输入正确的手机号'))
            }
            callback()
          }, trigger: 'blur' }
        ],
        Desc: [
          { required: true, message: '请输入反馈意见', trigger: 'blur' }
        ]
      }
    }
  },
  watch: {
    setoldquesson(a) {
      if (a) {
        return this.form.user = JSON.parse(this.getCookie('UserInfo')).UserName
      }
      return this.$refs['form'].resetFields()
    }
  },
  methods: {
    getCookie(name) {
      var arr; var reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)')
      // eslint-disable-next-line no-cond-assign
      if (arr = document.cookie.match(reg)) { return unescape(arr[2]) } else { return null }
    },
    openSetoldQuession() {
      this.setoldquesson = true
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return false
        this.$api.SaveFeedBack(this.form).then(res => {
          if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
          this.setoldquesson = false
          this.$message.success('提交成功')
        })
      })
    }
  }
}

</script>
<style lang="scss" scoped>
.submit-set {
    width: 100%;
}
</style>

// 使用 Mock
const Mock = require('mockjs')
const Random = Mock.Random
Random.csentence()
Random.natural()
Random.city()
Random.extend({
  constellation: function(date) {
    var constellations = ['青岛益信医学科技有限公司', '上海润达医疗科技股份有限公司']
    return this.pick(constellations)
  }
})
Random.constellation()
Random.extend({
  constellation1: function(date) {
    var constellations = ['test100001', 'test100002', 'test100003', 'test100003s']
    return this.pick(constellations)
  }
})
Random.constellation1()
// 商品列表
const mock = Mock.mock({
  // 属性 list 的值是一个数组，其中含有 1 到 10 个元素
  'list|1-10': [{
    // 属性 id 是一个自增数，起始值为 1，每次增 1
    'id|+1': 1,
    StdInvName: '@csentence(1, 5)',
    InvCode: '@natural(11008035,11008050)',
    MaterialSubTypeName: '试剂/血液/血型',
    BrandName: '@city',
    SellCompanyName: '@constellation',
    Specification: '50人份/盒',
    StockUnitN: '盒',
    DiscountAmount: 0,
    DiscountAmount1: 0,
    ContractName: '@constellation1',
    LatestAmount: 0
  }]
})

export default mock

<template>
  <div class="midSearch">
    <!-- <el-image src="../static/logo.png" /> -->
    <div class="left_abc ">
      <div class="image_veiw cursor" @click="$router.push('/order/orderMananger/orderList')" />
      <div class="main_vi">

        <div class="btn select_none" style="width:80px">
          <el-badge value="" :max="99">
            我的待办
          </el-badge>
        </div>
        <div class="btn select_none" style="width:120px">
          <el-badge :value="infoList['RegistCertificate'].nums" :max="99">
            注册证过期产品
          </el-badge>
        </div>
        <div class="btn select_none" style="width:120px">
          <el-badge :value="infoList['CompanyCertificate'].nums" :max="99">
            企业证件即将过期
          </el-badge>
        </div>
        <div class="btn select_none" style="width:100px">
          <el-badge :value="infoList['MaterialValid'].nums" :max="99">
            即将过期产品
          </el-badge>
        </div>
      </div>
    </div>

    <div class="right  hidden-sm-and-down">

      <el-col
        v-show="checkShow('通讯录')"
        :span="5"
        :push="6"
        class="flex column a-center"
      >
        <!-- <el-tooltip class="item" effect="dark" content="员工管理" placement="bottom-start">
          <i class="el-icon-notebook-2 cursor f30" @click="$router.push({ name: 'contactsIndex1'})" />
        </el-tooltip> -->
      </el-col>
      <el-tooltip class="item" effect="dark" content="在线客服" placement="bottom-start">
        <el-col
          :span="5"
          :push="6"
          class="flex column a-center"
        >
          <a target="blank" style="color:#555" href="tencent://message/?Menu=yes&uin=3592166621&Service=300&sigT=45a1e5847943b64c6ff3990f8a9e644d2b3">
            <i class="cursor el-icon-service f30" />
          </a>
        </el-col>
      </el-tooltip>
      <el-col :span="5" :push="6" class="flex column a-center">
        <el-tooltip class="item" effect="dark" content="站内信" placement="bottom-start"> <i class="cursor el-icon-bell f30" />
        </el-tooltip>
      </el-col>
      <el-tooltip class="item" effect="dark" content="个人中心" placement="bottom-start">
        <el-col
          v-show="checkShow('个人中心')"
          :span="5"
          :push="6"
          class="flex column a-center"
        >
          <el-popover
            v-model="visible"
            placement="bottom"
            trigger="manual"
            :width="80"
          >
            <div class="flex column">
              <div class="p-bottom-5 cursor ac_hover" @click="chioce(0)">企业信息</div>
              <div class="cursor p-bottom-5 ac_hover" @click="chioce(1)">账号信息</div>
              <div class="p-bottom-5 cursor ac_hover" @click="chioce(2)">修改密码</div>
              <!-- <div class="p-bottom-5 cursor ac_hover" @click="chioce(3)">意见反馈</div> -->
            <!-- <div class="cursor ac_hover" @click="chioce(3)">退出登录</div> -->

            </div>
            <i slot="reference" style="font-size:30px;border: none;" class="cursor el-icon-user f30" @click="visible = !visible" />
          </el-popover>
        </el-col>
      </el-tooltip>

    </div>
    <div class="el-icon-more_">
      <!--  @command="handleCommand" -->
      <el-dropdown>
        <span class="el-dropdown-link">
          <i class="el-icon-more" style="color:#fff" />
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="a"><i class="cursor el-icon-headset f25" /></el-dropdown-item>
          <el-dropdown-item command="b"><i class="cursor el-icon-bell  f25" /></el-dropdown-item>
          <el-dropdown-item command="c"><i class="cursor el-icon-user  f25" /></el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

    </div>
    <SetPsd ref="setPsd" />
  </div>
</template>
<script>
export default {
  name: 'MidSearch',
  data() {
    return {
      isShow: false,
      userMeaus: [],
      visible: false,
      dialogTableVisible1: false,
      infoList: { RegistCertificate: { nums: '' }, CompanyCertificate: { nums: '' }, MaterialValid: { nums: '' }}
    }
  },
  mounted() {
    this.getMeauByUser()
    if (process.env.NODE_ENV !== 'production') {
      this.GetNums()
    }
  },
  methods: {
    GetNums() {
      this.$api.GetNums().then(res => {
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        res.Message.map(item => {
          if (!this.infoList[item.str_key].nums) {
            if (item.nums * 1 < 10 && item.nums * 1 > 0) {
              item.nums = '0' + item.nums
            }
            if (item.nums <= 0) {
              item.nums = ''
            }
            // console.log(item)
            this.infoList[item.str_key] = item
          }
        })
        // console.log(this.infoList)
      }).catch(err => {
        return this.$message.error('错误：' + err)
      })
    },
    // 获取人员可访问菜单
    async getMeauByUser() {
      const _this = this
      await this.$jurisdiction.GetUserTierMenuList().then(res => {
        if (res.RetCode === '0') {
          _this.userMeaus = res.Message
        } else {
          // 如果获取人员菜单权限报错 则移除token
          localStorage.removeItem('token')
        }
      })
    },
    checkShow(title) {
      let result = false
      if (this.userMeaus) {
        this.userMeaus.forEach(ele => {
          if (ele.DisplayName === title) {
            result = true
            return false
          }
        })
      }
      return result
    },
    chioce(type) {
      this.visible = false
      if (type === 0) {
        //  企业信息 '/userInfo/EnterprisInfo'
        this.$router.push({
          name: 'EnterprisInfo'
        })
      }
      if (type === 1) {
        //  账号信息
        this.$router.push({
          name: 'accountInfo'
        })
      }

      if (type === 2) {
        //  修改密码
        this.$refs.setPsd.openSetoldpwd()
      }
      if (type === 3) {
        // //  退出登录
        // localStorage.removeItem('token')
        // this.$router.push('/login')
        // 意见反馈
        this.$refs.SetoldQuession.openSetoldQuession()
      }
    }
  }
}
</script>
<style lang='scss' scoped>
.midSearch{
    width: 100%;
    height: 80px;
    // min-width: 768px;
    background: #fff;
    margin: 0;
    color: #fff;
    position: fixed;
    top: 30px;
    left: 0;
    display: flex;
    justify-content:space-between;
    align-items: center;
    box-shadow: 0px 0px 10px rgba(0,0,0,.1);
    z-index: 99;
    .left_abc{
        flex: 1;
        height: 100%;
        display: flex;
      .image_veiw{
        width:  150px;
        height: 100%;
        background-image: url('https://static.mai47.com/static/saas/img/logo.png');
        background-size: contain;
        background-repeat: no-repeat;
        //background-position: left 57px center;

      }
      .main_vi{
        flex: 1;
        height: 100%;
        // padding-bottom: 30px;
          // width: 100%;
          display: flex;
          // justify-content: space-between;
          align-items: center;
          justify-content: flex-start;
          padding-left: 50px;
          .btn{
            width: auto;
            height: 30px; line-height: 28px;
            border-radius: 30px;
            border: 2px solid #eee;
            font-size: 15px;
            text-align: center;
            padding: 0 15px;
            margin: 0 0 0 15px;
            cursor: pointer;
            color: #333;
          }

      }
    }
      .right{
        width:auto;
        height: 100%;
        min-width: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #555;
        text-align: right;
        margin-right: 50px;
      }
}
.el-icon-more_{
  display: none;
}
.ac_hover:hover {
  color: #66beff
}
@media screen and (max-width: 991px) {
    .el-icon-more_{
        flex: 1;
        display: block;
        margin-right: 20px;
        text-align: right;
    }
}
</style>

<template>
  <div class="orderList">
    <Head />
    <MidSearch />
    <div class="main_view">
      <Nav />
      <AppMain />
    </div>
  </div>
</template>
<script>
import Head from '@/components/Head'
import MidSearch from '@/components/midSearch'
import Nav from '@/components/nav'
import AppMain from '@/components/appMain'
import mock from '@/request/mock'
export default {
  name: 'Index',
  components: {
    Head,
    Nav,
    MidSearch,
    AppMain
  },
  data() {
    return {
      list: [],
      checkedList: [],
      isAllChioce: false
    }
  },
  watch: {
    list: {
      handler(a) {
        a.map(item => {
          if (item.checked) {
            this.checkedList.push(item)
            this.checkedList = [...new Set(this.checkedList)]
          } else {
            if (this.checkedList.indexOf(item) !== -1) {
              this.checkedList.splice(this.checkedList.indexOf(item), 1)
            }
          }
        })
      },
      deep: true
    },
    checkedList: {
      handler(a) {
        if (a.length === this.list.length) {
          this.isAllChioce = true
        } else {
          this.isAllChioce = false
        }
      },
      deep: true
    }
  },
  mounted() {
    this.list = mock.list
    // console.log(this.list)
    // this.$api.getList({ pageIndex: 1 }).then(res => {
    //   console.log(res)
    // })
  },
  methods: {
    isAll(e) {
      if (e) return this.list.map(item => { this.$set(item, 'checked', true) })
      this.list.map(item => { this.$set(item, 'checked', false) })
      this.checkedList = []
    },
    to_car() {
      // console.log('checkedList', this.checkedList)
      // 购物车数据
      // console.log(this.$store.state.index.carList)
      // this.$store.dispatch('index/setCarList', this.checkedList)
      this.$router.push({
        name: 'Car',
        query: {
          id: 1
        }
      })
    }
  }
}
</script>
<style lang='scss' scoped>
.orderList{
  width: 100%;
  .main_view{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
    left: 0;
    top: 110px;
    overflow-y: hidden;
    background: #F5F6FA;

    .test{
       flex: 1;
    }
  }
}
.el-header {
    position: relative;
    width: 100%;
    height: 100px;
    padding: 0;
}
.el-aside {
  display: block;
  position: absolute;
  left: 0;
  top: 100px;
  bottom: 0;
}
.el-main {
  position: absolute;
  left: 200px;
  right: 0;
  top: 100px;
  bottom: 0;
  overflow-y: scroll;
}
.el-header{
    background-color: #fff;
    color: #333;
    text-align: center;
    // line-height: 60px; .el-footer
}

  .el-aside {
    background-color: #D3DCE6;
    color: #333;
    text-align: center;
    line-height: 200px;
  }

  .el-main {
    background-color: #E9EEF3;
    color: #333;
    text-align: center;
    line-height: 160px;
  }

</style>


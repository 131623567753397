<template>
  <div class="problem">
    <i class="el-icon-message cursor ii" @click="() => $refs.SetoldQuession.openSetoldQuession()" />
    <SetoldQuession ref="SetoldQuession" />
  </div>
</template>
<script>
import SetoldQuession from '@/components/QuestionFeedback'
export default {
  name: 'Problem',
  components: { SetoldQuession }
}
</script>
<style lang="scss" scoped>
.problem {
    position: fixed;
    right: 5px;
    bottom: 5px;
    z-index: 9999;
    padding:10px 10px 8px 10px;
    background: rgba(255,255,255,.6);
    border-radius: 4px;
    box-shadow:0px 5px 10px rgba(	25,25,112, 0.15);
    .ii {
        font-size: 25px;
        color: #409EFF;
    }
}
</style>

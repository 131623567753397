<template>
  <div class="nav">
    <!-- <fragment> -->
    <div class="quit">
      <i
        :class="isCollapse ? 'el-icon-s-unfold cursor':'el-icon-s-fold cursor' "
        style=" padding-right: 6px;"
        @click="quit"
      />
    </div>

    <el-aside class="inner-container" :width="width_" style="flex:1;margin-top:10px;">
      <!-- :default-openeds="defaultOpeneds" -->
      <el-menu v-for="(item,index) in router" :key="index" :default-active="getAcx" :collapse-transition="false" style="border:none" :collapse="isCollapse">
        <div v-if="!item.all">
          <div v-if="checkShow(item.meta.title)" class="meau">
            <el-menu-item v-if="item.children.length <= 1" :index="item.meta.title" class="submenu-title-noDropdown" @click="toRouter(item.children[0])">
              <item :icon="item.children[0].meta.icon||(item.children[0].meta&&item.children[0].meta.icon)" :title="item.children[0].meta.title" />
            </el-menu-item>

            <template v-else>
              <el-submenu :index="index+''">
                <template slot="title">
                  <div @click="GuidepageEve(item.meta.title)">
                    <item
                      v-if="item.meta && checkShow(item.meta.title)"
                      :icon="item.meta && item.meta.icon"
                      :title="isCollapse ? '' : item.meta.title"
                    />
                  </div>

                </template>
                <el-menu-item-group>
                  <template v-for="(i,n) in item.children">
                    <el-menu-item v-if="!i.hidden && checkShow(i.meta.title)" :key="n" :index="i.meta.title" @click="toRouter(i)">{{ i.meta.title }}</el-menu-item>
                  </template>
                </el-menu-item-group>
              </el-submenu>

            </template>

          </div>
        </div>
      </el-menu>

    </el-aside>
    <Guidepage ref="Guidepage" :title="titleName" :type="typeNum" @close="closeA" />
  </div>
</template>
<script>
import router from '@/router/router.js'
import Item from '@/components/Item'
import $jurisdictionReques from '@/request/jurisdictionApi.js'
export default {
  name: 'Nav',
  components: {
    Item
  },
  data() {
    return {
      defaultOpeneds: [],
      width_: '180px',
      isCollapse: false,
      abc: '',
      router,
      userMeaus: [],
      titleName: '',
      typeNum: 0
    }
  },

  computed: {
    getAcx() {
      const index = this.$route.meta.title
      return index
    }
  },
  mounted() {
    this.getMeauByUser()
    // this.$store.state.index.tags.push(router[0].children[0])
    // this.$store.dispatch('index/setTags', this.$store.state.index.tags)
    // console.log(this.$store.state.index.cachedViews)
    router.map(item => {
      if (item.all) return
      item.children.map(item1 => {
        if (item1.meta.noCache) {
          this.$store.state.index.cachedViews.push(item1.name)
          this.$store.dispatch('index/setCachedViews', this.$store.state.index.cachedViews)
        }
      })
    })
  },
  methods: {
    GuidepageEve(MeauName) {
      // console.log('MeauName', MeauName)
      if (MeauName) {
        this.titleName = MeauName
        if (this.titleName === '首营管理') {
          this.typeNum = 1
        } else if (this.titleName === '仓储管理') {
          this.typeNum = 2
        } else if (this.titleName === '订单管理') {
          this.typeNum = 3
        } else if (this.titleName === '采购管理') {
          this.typeNum = 4
        } else {
          this.typeNum = 0
        }
        if (this.typeNum) {
          this.$api.CheckUserLeadRecord({ MeauName }).then(res => {
            const { RetCode, RetMsg } = res
            if (RetCode === '0') return false
            if (RetCode !== '0' && RetCode !== '-1') return this.$message.error('引导页检查接口错误：' + RetMsg)
            if (RetCode === '-1') return this.$refs.Guidepage.openGuidepagedialog()
          })
        }
      }
    },
    closeA() {
      this.typeNum = 0
    },
    quit() {
      this.isCollapse = !this.isCollapse
      this.width_ = this.isCollapse ? 'auto' : '180px'
      if (this.isCollapse) {
        document.getElementsByTagName('body')[0].style.setProperty('--width-primary', 'none')
      }
      if (!this.isCollapse) {
        document.getElementsByTagName('body')[0].style.setProperty('--width-primary', 'block')
      }
    },
    toRouter(item) {
      this.$router.push({
        name: item.name
      })
    },
    // 获取人员可访问菜单
    async getMeauByUser() {
      const _this = this
      await $jurisdictionReques.GetUserTierMenuList().then(res => {
        if (res.RetCode === '0') {
          _this.userMeaus = res.Message
          // 存入缓存
          localStorage.setItem('userMeaus', JSON.stringify(_this.userMeaus))
        } else {
          // 如果获取人员菜单权限报错 则移除token
          localStorage.removeItem('token')
          // 重新加载页面
          window.location.reload()
        }
      })
    },
    checkShow(title) {
      let result = false
      if (this.userMeaus) {
        this.userMeaus.forEach(ele => {
          if (ele.DisplayName === title) {
            result = true
            return false
          }
        })
      }
      return result
    }
  }
}
</script>
<style lang='scss'>
.el-submenu__icon-arrow{
  display: var(--width-primary) !important;
}
 .el-menu{ background:#F5F6FA;}

 .el-submenu .el-menu-item{ min-width: 80%; display: inline-block;height:35px; line-height:35px;padding:0 0 0 30px!important; text-align: left; margin-left: 20px;}
 .el-submenu .el-menu-item:hover{background:#fff; color: #3E76ED; border-radius: 4px;  min-width:80%; padding-left:30px!important; display: inline-block;  text-align: left; margin-left: 20px;}
 .el-menu-item.is-active{ background:url(../static/jiantou-blue.png) no-repeat right 10px center #fff; color: #3E76ED; border-radius: 4px; min-width:80%; padding-left:30px!important; display: inline-block;  text-align: left; margin-left: 20px; }
 .el-menu-item, .el-submenu__title{min-width:80%!important;height: 35px;border-radius: 4px; line-height: 35px; padding-left: 10px!important; display: inline-block; margin-left: 20px;}
 .el-submenu.is-active .el-submenu__title{ background:#3E76ED; color: #fff; height: 35px; line-height: 35px; padding-left:10px; display: inline-block; text-align: left; margin:0 20px; box-shadow: 0 0 8px rgba(0,0,0,.2);}
 .el-submenu .el-submenu__title:hover{ background:#3E76ED; color: #fff;height: 35px; line-height: 35px; padding-left:10px; display: inline-block; text-align: left; margin-left: 20px;}
 .sub-el-icon{ margin-right: 10px;}
 .is-active .el-submenu__title i{ color: #fff;}
 .el-submenu__title:hover i{ color: #fff;}

.meau li.el-menu-item:hover{background:#3E76ED; color: #fff; height: 35px; line-height: 35px; padding-left:10px; display: inline-block; text-align: left; margin:0 20px; box-shadow: 0 0 8px rgba(0,0,0,.2);}
 ul.el-menu--collapse{ margin-bottom: 0!important;}
 ul.el-menu--collapse li .el-submenu__title{margin: 0!important;padding-left:0!important;padding-right:0!important; text-align: center!important; box-sizing: border-box; width: 100%; border-radius: 0;height: 40px; line-height: 40px;}
  ul.el-menu--collapse li .el-submenu__title:hover{ height: 40px; line-height: 40px;}
 ul.el-menu--collapse li .el-submenu__title i{ margin-right: 0px; }
 ul.el-menu--collapse .meau li{ margin-left: 0px;width: 100%; padding-left: 0!important; padding-right: 0;border-radius: 0px;height: 40px; line-height: 40px;}
 ul.el-menu--collapse .meau li .el-tooltip{padding-left: 0!important; padding-right: 0!important; text-align: center; border-radius: 0px;}
 ul.el-menu--collapse .meau:hover li{padding-left: 0!important; padding-right: 0!important; text-align: center; border-radius: 0px; margin: 0;height: 40px!important; line-height: 40px!important;}
 ul.el-menu--collapse .meau:hover li .el-tooltip{height: 40px!important; line-height: 40px!important; margin-bottom: 0px!important;}
 ul.el-menu--collapse .meau li .el-tooltip i{ margin-right: 0;}
 ul.el-menu--collapse .meau li.is-active .el-tooltip{ background-color:#3E76ED;color:#fff;}
 .el-menu--collapse{ width: 40px!important;}
 ul.el-menu--collapse .meau li.is-active .el-submenu__title{ background:#3E76ED; color: #fff; height: 40px; line-height: 40px; padding-left:10px; display: inline-block; text-align: left; margin:0 20px; box-shadow: 0 0 8px rgba(0,0,0,.2);}

.el-menu--vertical{ width: 180px; box-sizing: border-box;}
.el-menu--vertical ul{ background-color: #ffffff;box-sizing: border-box; border-radius: 0 4px 4px 0}
.el-menu--vertical ul li{ box-sizing: border-box; margin: 0 auto!important; width: 100%;}
.el-menu--vertical ul.el-menu{padding: 3px 10px 10px 10px;}
.el-menu--vertical ul.el-menu li.el-menu-item-group ul li{margin-bottom: 5px; color: #333; height: 24px; line-height: 24px; text-align: left; padding-left: 10px!important;}
.el-menu--vertical ul.el-menu li.el-menu-item-group ul li:hover{ background:rgba(62,118,237,.1); color: #3E76ED;}
.el-menu--vertical ul.el-menu li.el-menu-item-group ul li.is-active{background:#3E76ED; color: #fff;}

.el-menu--popup-right-start{ margin-left: 0;}

</style>
<style lang='scss' scoped>
.nav{
    height: calc(100vh - 110px);
    // position: fixed;
    // left: 0;
    // top: 110px;
    overflow: auto;
    background-color: #F5F6FA;
    display: flex;
    flex-direction: column;
    // z-index:8888;
    .quit{
        width: 100%;
        height:30px;
        font-size: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        i.el-icon-s-unfold{ padding:11px 9px 0 0!important;}
    }
    ul{ margin-bottom: 5px;}
}

.inner-container::-webkit-scrollbar {
  display: none;
}
.nav::-webkit-scrollbar {
  display: none;
}

/*隐藏文字*/
.el-menu--collapse  .el-submenu__title span{
  display: none;
}
/*隐藏 > */
.el-menu--collapse  .el-submenu__title .el-submenu__icon-arrow{
  display: none;
}

</style>


<template>
  <section class="app-main">
    <Tags />
    <transition name="fade-transform" mode="out-in">
      <!--      :include="cachedViews" :key="key" -->
      <keep-alive :include="cachedViews">
        <router-view :key="key" style="background-color: #fff;min-height:calc(100% - 150px)" />
      </keep-alive>
    </transition>
    <Problem />
    <Foot />
    <webType />
  </section>
</template>

<script>

import Tags from '@/components/tags'
import Foot from '@/components/foot'
import Problem from '@/components/problem'
import webType from '@/components/webType' // 360SE
export default {
  name: 'AppMain',
  components: { Tags, Foot, Problem, webType },
  // this.$store.state.tagsView.cachedViews
  computed: {
    cachedViews() {
      return this.$store.state.index.cachedViews
    },
    key() {
      this.$store.dispatch('index/setPath', this.$route.path)
      return this.$route.fullPath
    }
  }
  // create() {
  //   window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
  // }
}
</script>

<style lang="scss" scoped>
.app-main {
  /* 50= navbar  50  */
  // width: calc(100% -200px);
  // min-width: 780px;
 height: calc(100vh - 110px);
  padding: 0 15px;
  overflow:auto;
  background-color:#DDE3F1;

//   width: 100%;
flex: 1;
// position: relative;
// right: 0;
  // background-color: #fff;
//   overflow: hidden;
}
.fixed-header+.app-main {
  padding-top: 50px;
}
.app-main::-webkit-scrollbar {
  display: none;
}
// .hasTagsView {
//   .app-main {
//     /* 84 = navbar + tags-view = 50 + 34 */
//     min-height: calc(100vh - 84px);
//   }
//   .fixed-header+.app-main {
//     padding-top: 84px;
//   }
// }
</style>

<style lang="scss">
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>

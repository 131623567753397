<template>
  <div class="webType" />
</template>
<script>
export default {
  created() {
    console.log(navigator.userAgent)
    const this_ = this
    var browser = {
      versions: (function() {
        var u = navigator.userAgent
        return {
          se: u.indexOf('360SE') > -1, // 360兼容模式
          compatible: u.indexOf('compatible') > -1, // 360兼容模式
          trident: u.indexOf('Trident') > -1, // IE内核
          presto: u.indexOf('Presto') > -1, // opera内核
          webKit: u.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
          gecko: u.indexOf('Firefox') > -1, // 火狐内核Gecko
          mobile: !!u.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
          ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios
          android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, // android
          iPhone: u.indexOf('iPhone') > -1, // iPhone
          iPad: u.indexOf('iPad') > -1, // iPad
          webApp: u.indexOf('Safari') > -1 // Safari
        }
      }())
    }
    console.log(browser.versions)
    if (browser.versions.se) {
      this_.$alert('123', '123', {
        confirmButtonText: '31232',
        callback: action => {
          window.open('https://www.google.cn/chrome/', '_self')
        }
      })
    }
    if (browser.versions.compatible) {
      this_.$alert('该系统不支持360兼容模式', '360兼容模式', {
        confirmButtonText: '我知道了',
        callback: action => {
          window.open('https://www.google.cn/chrome/', '_self')
        }
      })
    }
    if (browser.versions.trident) {
      this_.$alert('请更换谷歌浏览器使用该系统！', '浏览器版本过低', {
        confirmButtonText: '我知道了',
        callback: action => {
          window.open('https://www.google.cn/chrome/', '_self')
        }
      })
    }
    if (browser.versions.mobile || browser.versions.ios || browser.versions.android || browser.versions.iPhone || browser.versions.iPad) {
      this_.$alert('请移步至电脑端体验该系统', '移动端暂不兼容', {
        confirmButtonText: '确定',
        callback: action => {
        //   window.open('https://www.google.cn/chrome/', '_self')
        }
      })
    }
  }
}
</script>

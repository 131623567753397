import { render, staticRenderFns } from "./tags.vue?vue&type=template&id=41fe63db&scoped=true&"
import script from "./tags.vue?vue&type=script&lang=js&"
export * from "./tags.vue?vue&type=script&lang=js&"
import style0 from "./tags.vue?vue&type=style&index=0&id=41fe63db&scoped=true&lang=css&"
import style1 from "./tags.vue?vue&type=style&index=1&id=41fe63db&lang=scss&scoped=true&"
import style2 from "./tags.vue?vue&type=style&index=2&id=41fe63db&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41fe63db",
  null
  
)

export default component.exports